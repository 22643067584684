/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { Link } from "gatsby";

export default function footer() {
  return (
    <footer>
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-4 col-md-2">© {new Date().getFullYear()} Lendmarx, LLC</div>
          <ul className="homepage-footer-links col-8 col-md-8 col-lg-9 text-right">
            <li>
              <Link to={"/"}>Home</Link>
            </li>
            <li>
              <a href="/privacy-policy" target="_blank">
                Privacy Policy
              </a>
            </li>
            <li>
              <a href="/terms-and-conditions" target="_blank">
                Terms and Conditions
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://healthy-quotes.com/tcpa"
                rel="noreferrer"
              >
                TCPA Partners
              </a>
            </li>
          </ul>

          <div className="col-12 mt-4" style={{fontSize: "12px"}} >
            G. Wilkinson Life & Health Ins. Agency Inc.
            operates this website, healthy-quotes.com, as a licensed third-party
            broker in order to help individuals, families and small business
            owners obtain ACA-compliant qualifying health plans (QHPs) from a
            variety of health carriers and insurance companies. G. Wilkinson
            Life & Health Ins. Agency Inc. has been independently verified by
            Verisk Financial G2, with registered status as an "Approved Health
            Insurance Provider." G. Wilkinson Life & Health Ins. Agency Inc.
            offers consumers the opportunity to enroll in QHPs, off-Marketplace
            coverage, affordable alternatives, ancillary accident, and life
            insurance products. Please note that federal and state subsidy
            assistance only applies to "on-market" plans offered by the state
            exchanges or listed at healthcare.gov. This website is not
            affiliated with the federal Health Insurance Marketplace® website;
            nor is this site affiliated with any of the state insurance
            exchanges.
          </div>
        </div>
      </div>
    </footer>
  );
}
