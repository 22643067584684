import React from "react";
import * as CONST from "../../CONSTANTS";
import { isBrowser, findGetParameter } from "../../HELPERS";
import * as ECONST from "./ECONSTANTS";

const getMMDDYYY = dobObject => {
  let dob = Date.now();
  if (dobObject[CONST.MONTH] && dobObject[CONST.DAY] && dobObject[CONST.YEAR]) {
    dob = `${("0" + dobObject[CONST.MONTH][CONST.SELECT_VALUE]).slice(-2)}/${(
      "0" + dobObject[CONST.DAY][CONST.SELECT_VALUE]
    ).slice(-2)}/${dobObject[CONST.YEAR][CONST.SELECT_VALUE]}`;
  }
  return dob;
};

const sendLeadToLeadspedia = async fData => {
  const reqid = window.reqid ? window.reqid : "";
  const pub_id = window.pub_id ? window.pub_id : "";
  //const sub_id = window.sub_id ? window.sub_id : "";
  const lp_request_id = window.lp_request_id ? window.lp_request_id : "";
  const s1 = window.s1 ? window.s1 : "";
  const s2 = window.s2 ? window.s2 : "";
  const s3 = window.s3 ? window.s3 : "";
  const { lp_campaign_id, lp_campaign_key } = getSupplierCredentials(pub_id);
  const trusted_form_token = window.trustedForm.id;
  const trusted_form_cert_url = document.getElementById(
    "xxTrustedFormCertUrl_0"
  ).value;
  const jornaya_lead_id = window.LeadiD.token;
  const consent_language = `By clicking the button and submitting this form, I agree that I am 18+ years old and agree to the Privacy Policy and Terms & Conditions. By clicking the button and submitting this form, I provide my signature giving express consentto receive marketing communications via automated telephone dialing systems, artificial or pre-recorded voices, emails, live phone calls, pre-recorded calls, postal mail, text messages via SMS or MMS and other forms of communication regarding offers for Medicare Supplement, Medicare Advantage, Part D, Health Insurance, ACA and other senior home, health and assistance products from Lendmarx and/or one or more of its marketing partners and agents to the number(s) and/or email I provided, including a mobile phone, even if I am on a state or federal Do Not Call and/or Do Not Email registry. Agents are not connected with or endorsed by the U.S. government or the federal Medicare program. The list of companies participating are subject to change. I understand my wireless carrier may impose charges for calls or texts. I understand that my consent to receive communications is not a condition of purchase and I may revoke my consent at any time. To receive quotes without providing consent, please call ${getCurrentPhoneNumberFormatted()}.`;

  const _formData = fData[CONST.DATA];

  const gender = _formData[CONST.CN_GENDER];
  const firstName = _formData[CONST.CN_NAME][CONST.DATA][CONST.NAME_FNAME];
  const lastName = _formData[CONST.CN_NAME][CONST.DATA][CONST.NAME_LNAME];
  const phone = _formData[CONST.CN_PHONE];
  const email = _formData[CONST.CN_EMAIL];
  const dob = getMMDDYYY(_formData[CONST.CN_DOB][CONST.DATA]);
  const address = _formData[CONST.CN_ADDRESS][CONST.DATA][CONST.ADDRESS_STREET];
  const city = _formData[CONST.CN_ADDRESS][CONST.DATA][CONST.ADDRESS_CITY];
  // const state =
  //   _formData[CONST.CN_ADDRESS][CONST.DATA][CONST.ADDRESS_STATE][
  //     CONST.SELECT_VALUE
  //   ];
  const zip_code = _formData[CONST.CN_ZIP_CODE];
  const user_agent = navigator.userAgent;
  const landing_page = window.location.host;

  const interested_benefits = _formData[CONST.CN_INTERESTED_BENEFITS];
  const policy = [_formData[CONST.CN_POLICY_TYPE]];

  let formdata = new FormData();
  formdata.append("lp_campaign_id", lp_campaign_id);
  formdata.append("lp_campaign_key", lp_campaign_key);
  formdata.append("lp_request_id", lp_request_id);
  formdata.append("lp_s1", s1);
  formdata.append("lp_s2", s2);
  formdata.append("lp_s3", s3);
  formdata.append("transaction_id", reqid);
  formdata.append("Pub_ID", s1);
  formdata.append("Sub_ID", s2);
  formdata.append("trusted_form_cert_id", trusted_form_token);
  formdata.append("trusted_form_cert_url", trusted_form_cert_url);
  formdata.append("jornaya_lead_id", jornaya_lead_id);
  formdata.append("gender", gender);
  formdata.append("first_name", firstName);
  formdata.append("last_name", lastName);
  formdata.append("phone_home", phone);
  formdata.append("zip_code", zip_code);
  formdata.append("email_address", email);
  formdata.append("dob", dob);
  formdata.append("address", address);
  formdata.append("city", city);
  // formdata.append("state", state);
  formdata.append("user_agent", user_agent);
  formdata.append("landing_page", landing_page);
  formdata.append("consent_language", consent_language);
  formdata.append("lp_response", "JSON");
  formdata.append("tcpa_consent", "Yes");

  //temporary until fixed
  formdata.append("jornay_lead_id", jornaya_lead_id);

  formdata.append("interested_benefits", interested_benefits);
  formdata.append("policy", policy);

  let requestOptions = {
    method: "POST",
    body: formdata,
    redirect: "follow",
  };

  console.log(JSON.stringify(formdata));

  return fetch(
    "https://smartmarket.leadspediatrack.com/post.do",
    requestOptions
  )
    .then(response => response.text())
    .then(result => {
      let lp_response = JSON.parse(result);
      if (lp_response.result === ECONST.LP_SUCCESS) {
        console.log("Success!");
        return {
          postSuccess: true,
          leadID: lp_response.lead_id,
          leadPrice: lp_response.price,
        };
      } else {
        console.log("Error!");
        return {
          postSuccess: false,
          leadID: "",
        };
      }
    })
    .catch(error => console.log("error", error));
};

/**
 * Return {lp_campaign_id, lp_campaign_key} for given supplier
 * @param {string} id Supplier id
 */
const getSupplierCredentials = id => {
  let data = ECONST.LP_SUPPLIER_CREDENTIALS["default"];

  if (id && id.toLocaleLowerCase() in ECONST.LP_SUPPLIER_CREDENTIALS) {
    const supplierData = ECONST.LP_SUPPLIER_CREDENTIALS[id];
    if (supplierData.lp_campaign_id) {
      data.lp_campaign_id = supplierData.lp_campaign_id;
    }
    if (supplierData.lp_campaign_key) {
      data.lp_campaign_key = supplierData.lp_campaign_key;
    }
    if (supplierData.phoneNumber) {
      data.phoneNumber = supplierData.phoneNumber;
    }
  }
  return data;
};

const getCurrentPhoneNumber = () => {
  let phoneNumber = ECONST.LP_DEFAULT_PHONE;
  const getPhone = findGetParameter("phoneNumber");
  const pubId = findGetParameter("pub_id");

  if (isBrowser()) {
    if (getPhone && getPhone.match(/\d{11}/)) {
      console.log("phone in url");
      return getPhone;
    }

    if (pubId in ECONST.LP_SUPPLIER_CREDENTIALS) {
      //hardcoded phones
      console.log("hardcoded phones");
      return getSupplierCredentials(pubId).phoneNumber;
    }

    if (typeof window.phoneNumber !== "undefined") {
      console.log("window.phone active");
      return window.phoneNumber;
    }
  }
  //default phone
  return phoneNumber;
};

const getCurrentPhoneNumberFormatted = () => {
  let phoneNumber = getCurrentPhoneNumber();
  return `${phoneNumber.slice(0, 1)}-${phoneNumber.slice(
    1,
    4
  )}-${phoneNumber.slice(4, 7)}-${phoneNumber.slice(7, 11)}`;
};

const defaultPhoneNumber = ECONST.LP_DEFAULT_PHONE;
const defaultPhoneNumberFormatted = getCurrentPhoneNumberFormatted();

function InlineLeadspediaScripts() {
  const inlineScriptVariables = ` 
  window._lp_public_key = "${ECONST.LP_PHONE_KEY}";
  window._lp_campaign_id = "${ECONST.LP_PHONE_CAMP_ID}";
  window._lp_format_number = "false";
  window._lp_number_class = "phone_number";`;
  const inlineScriptParsePhone = `
  const phoneNumber = document.querySelector(".phone_number")?.textContent;
  window.phoneNumber = phoneNumber;
  `;

  return (
    <>
      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{ __html: inlineScriptVariables }}
      ></script>
      {/* <script
        type="text/JavaScript"
        src="//www.leadspediatrack.com/js/phone.js"
      ></script> */}
      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{ __html: inlineScriptParsePhone }}
      ></script>
    </>
  );
}

export {
  sendLeadToLeadspedia,
  getCurrentPhoneNumber,
  getCurrentPhoneNumberFormatted,
  InlineLeadspediaScripts,
  defaultPhoneNumber,
  defaultPhoneNumberFormatted,
};
