//CUSTOM SETTINGS
export const TOTAL_PROGRESS_SCREENS = 10;

//STEP NAMES
export const CN_ZIP_CODE = "CN_ZIP_CODE";
export const CN_GENDER = "CN_GENDER";
export const CN_DOB = "CN_DOB";
export const CN_NAME = "CN_NAME";
export const CN_ADDRESS = "CN_ADDRESS";
export const CN_EMAIL = "CN_EMAIL";
export const CN_PHONE = "CN_PHONE";
export const CN_POLICY_TYPE = "CN_POLICY_TYPE";
export const CN_INTERESTED_BENEFITS = "CN_INTERESTED_BENEFITS";
export const CN_ONESTEP_FORM = "CN_ONESTEP_FORM";

export const GENDER_MALE = "Male";
export const GENDER_FEMALE = "Female";

export const ADDRESS_STREET = "ADDRESS_STREET";
export const ADDRESS_CITY = "ADDRESS_CITY";
export const ADDRESS_STATE = "ADDRESS_STATE";

export const NAME_FNAME = "NAME_FNAME";
export const NAME_LNAME = "NAME_LNAME";

export const POLICY_TYPE_INDIVIDUAL = "individual";
export const POLICY_TYPE_FAMILY = "family";

export const INTEREST_LOWER_PRICE = "lower_price";
export const INTEREST_BETTER_COVERAGE = "better_coverage";

export const LAYOUT_HOME_PAGE = "LAYOUT_HOME_PAGE";
export const LAYOUT_WIZARD_PAGE_SINGULAR = "LAYOUT_WIZARD_PAGE_SINGULAR";
export const LAYOUT_WIZARD_PAGE = "LAYOUT_WIZARD_PAGE";
export const LAYOUT_TCPA_PAGE = "LAYOUT_TCPA_PAGE";
export const LAYOUT_ORDINARY_PAGE = "LAYOUT_ORDINARY_PAGE";
export const LAYOUT_ONLY_HEADER = "LAYOUT_ONLY_HEADER";

export const SHOW_COMPONENT = "SHOW_COMPONENT";
export const NEXT_COMPONENT = "NEXT_COMPONENT";
export const DATA = "DATA";
export const COMPONENT_FLOW = "COMPONENT_FLOW";

export const CN_SEND_LEAD = "CN_SEND_LEAD";
export const CN_THANKS = "CN_THANKS";
export const CN_POSTING_ERROR = "CN_POSTING_ERROR";

export const BACK = "Back";
export const CONTINUE = "Continue";

export const SELECT_VALUE = "value";
export const SELECT_LABEL = "label";
export const MONTH = "Month";
export const DAY = "Day";
export const YEAR = "Year";
export const MONTHS_LIST = [
  { [SELECT_VALUE]: 1, [SELECT_LABEL]: "January" },
  { [SELECT_VALUE]: 2, [SELECT_LABEL]: "February" },
  { [SELECT_VALUE]: 3, [SELECT_LABEL]: "March" },
  { [SELECT_VALUE]: 4, [SELECT_LABEL]: "April" },
  { [SELECT_VALUE]: 5, [SELECT_LABEL]: "May" },
  { [SELECT_VALUE]: 6, [SELECT_LABEL]: "June" },
  { [SELECT_VALUE]: 7, [SELECT_LABEL]: "July" },
  { [SELECT_VALUE]: 8, [SELECT_LABEL]: "August" },
  { [SELECT_VALUE]: 9, [SELECT_LABEL]: "September" },
  { [SELECT_VALUE]: 10, [SELECT_LABEL]: "October" },
  { [SELECT_VALUE]: 11, [SELECT_LABEL]: "November" },
  { [SELECT_VALUE]: 12, [SELECT_LABEL]: "December" },
];
export const DATE_STATE_STRUCT = {
  [MONTH]: { [SELECT_VALUE]: 0, [SELECT_LABEL]: "Month" },
  [DAY]: { [SELECT_VALUE]: 0, [SELECT_LABEL]: "Day" },
  [YEAR]: { [SELECT_VALUE]: 0, [SELECT_LABEL]: "Year" },
};
export const DAYS_IN_MONTH = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

export const REGEX_EMAIL = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
export const REGEX_PHONE = /^\d{10}$/;
