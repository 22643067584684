import React from "react";

function initJornaya() {
  var s = document.createElement("script");
  s.id = "LeadiDscript_campaign";
  s.type = "text/javascript";
  s.async = true;
  s.src =
    "//create.lidstatic.com/campaign/437e8e0d-0180-f08b-f299-d57d0e14fbd4.js?snippet_version=2";
  var LeadiDscript = document.getElementById("LeadiDscript");
  LeadiDscript.parentNode.insertBefore(s, LeadiDscript);
}

function InlineJornayaScripts() {
  return (
    <>
      <script id="LeadiDscript" type="text/javascript"></script>
      <noscript>
        <img src="//create.leadid.com/noscript.gif?lac=aef81973-4d9a-34a8-2a91-18a7257d282e&lck=437e8e0d-0180-f08b-f299-d57d0e14fbd4&snippet_version=2" />
      </noscript>
    </>
  );
}

function JornayaHiddenInput() {
  return (
    <>
      <input id="leadid_token" name="universal_leadid" type="hidden" value="" />
    </>
  );
}

export { initJornaya, InlineJornayaScripts, JornayaHiddenInput };
