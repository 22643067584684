import * as React from "react";

import * as CONST from "../../CONSTANTS";
import * as ECONST from "./ECONSTANTS";
import { isBrowser, findGetParameter } from "../../HELPERS";
import { getCurrentPhoneNumberFormatted } from "./leadspedia";

function MediaalphaPlaceholder({ id }) {
  let idString = id ? `mediaalpha_placeholder-${id}` : "mediaalpha_placeholder";
  return <div id={idString}></div>;
}

const getYYYYMMDD = dobObject => {
  let dob = Date.now();
  if (dobObject[CONST.MONTH] && dobObject[CONST.DAY] && dobObject[CONST.YEAR]) {
    dob = `${dobObject[CONST.YEAR][CONST.SELECT_VALUE]}-${
      dobObject[CONST.MONTH][CONST.SELECT_VALUE]
    }-${dobObject[CONST.DAY][CONST.SELECT_VALUE]}`;
  }
  return dob;
};

const getUsPhoneFormat = phone => {
  return `(${phone.substring(0, 3)}) ${phone.substring(3, 6)}-${phone.substring(
    6
  )}`;
};

const initMediaalphaPlacement = (fData, id) => {
  const placeholderId = id
    ? `mediaalpha_placeholder-${id}`
    : "mediaalpha_placeholder";
  const trusted_form_token = window.trustedForm.id;
  const jornaya_lead_id = window.LeadiD.token;
  const pub_id = findGetParameter("pub_id");
  const sub_id = findGetParameter("sub_id");

  const _formData = fData[CONST.DATA];

  const gender = _formData[CONST.CN_GENDER] === CONST.GENDER_MALE ? "M" : "F";
  const phone = getUsPhoneFormat(_formData[CONST.CN_PHONE]);
  const email = _formData[CONST.CN_EMAIL];
  const dob = getYYYYMMDD(_formData[CONST.CN_DOB][CONST.DATA]);
  const address = _formData[CONST.CN_ADDRESS][CONST.DATA][CONST.ADDRESS_STREET];
  const zip_code = _formData[CONST.CN_ZIP_CODE];

  window.MediaAlphaExchange = {
    data: {
      zip: zip_code,
      leadid_id: jornaya_lead_id,
      trusted_form_certificate_id: trusted_form_token,
      birth_date: dob,
      gender: gender,
      phone: phone,
      email: email,
      address: address,
      tcpa: {
        text: `By clicking the button and submitting this form, 
				I agree that I am 18+ years old and agree to the Privacy 
				Policy and Terms & Conditions. By clicking the button and 
				submitting this form, I provide my signature giving express 
				consent to receive marketing communications via automated 
				telephone dialing systems, artificial or pre-recorded voices, 
				emails, live phone calls, pre-recorded calls, postal mail, 
				text messages via SMS or MMS and other forms of communication 
				regarding offers for Medicare Supplement, Medicare Advantage, 
				Part D, Health Insurance, ACA and other senior home, health and 
				assistance products from Lendmarx and/or one or more of its 
				marketing partners and agents to the number(s) and/or email I 
				provided, including a mobile phone, even if I am on a state or federal 
				Do Not Call and/or Do Not Email registry. Agents are not connected 
				with or endorsed by the U.S. government or the federal Medicare program. 
				The list of companies participating are subject to change. I understand my 
				wireless carrier may impose charges for calls or texts. I understand that my 
				consent to receive communications is not a condition of purchase and I may revoke 
				my consent at any time. To receive quotes without providing consent, please call ${getCurrentPhoneNumberFormatted()}.`,
      },
    },
    placement_id: ECONST.MA_PLACEMENT_THANKYOU_ID,
    sub_1: pub_id,
    sub_2: sub_id,
    type: "ad_unit",
    version: 17,
  };
  window.MediaAlphaExchange__load(placeholderId);
};

function initMediaalphaWithZip(zip) {
  console.log("medinit");
  let pub_id = "";
  let sub_id = "";
  if (isBrowser()) {
    pub_id = findGetParameter("pub_id");
    sub_id = findGetParameter("sub_id");
  }

  try {
    window.MediaAlphaExchange = {
      data: {
        zip: zip,
      },
      placement_id: ECONST.MA_PLACEMENT_EXITOFFERS_ID,
      sub_1: pub_id,
      sub_2: sub_id,
      type: "ad_unit",
      version: 17,
    };
    window.MediaAlphaExchange__load("mediaalpha_placeholder");
  } catch (err) {
    setTimeout(() => {
      initMediaalphaWithZip(zip); //in case their script is not loaded yet
    }, 100);
  }
}

export {
  MediaalphaPlaceholder,
  initMediaalphaPlacement,
  initMediaalphaWithZip,
};
