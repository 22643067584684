import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import logo from "../images/logo_final.svg";
import {
  getCurrentPhoneNumber,
  getCurrentPhoneNumberFormatted,
  defaultPhoneNumber,
  defaultPhoneNumberFormatted
} from "./thirdParty/leadspedia";

const Header = () => {
  const [phoneNumber, setPhoneNumber] = useState(defaultPhoneNumber);
  const [phoneNumberFormatted, setPhoneNumberFormatted] = useState(defaultPhoneNumberFormatted);

  useEffect(() => {
    setPhoneNumber(getCurrentPhoneNumber());
    setPhoneNumberFormatted(getCurrentPhoneNumberFormatted());
  }, []);

  return (
    <header>
      <div className="header-menu">
        <div className="container">
          <div className="row header-menu-row">
            <div className="col-6 col-md-5 d-flex align-items-center">
              <img
                className="header-menu-logo img-fluid"
                alt="site logo"
                src={logo}
              />
            </div>
            <div className="col-6 col-md-7 text-right d-flex align-items-center justify-content-end">
              <div className="d-none d-lg-block d-xl-block">
                <div className="header-menu-text-col">
                  <span className="header-menu-text-col-heading d-block">
                    Agents are currently available
                    <br />
                    to speak with you now
                  </span>
                  <a
                    className="header-menu-text-col-number btn btn-outline-primary btn-lg"
                    href={`tel:${phoneNumber}`}
                  >
                    {phoneNumberFormatted}
                  </a>
                </div>
              </div>
              <a
                className="header-menu-text-col-number-mobile d-lg-none d-xl-none text-center btn btn-outline-primary btn-lg"
                href={`tel:${phoneNumber}`}
              >
                Call to Agent
              </a>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
