export const CONNECTSTREAMS_MODAL_MODULEID = "1804";

export const SLINKY_LEAD_TYPE = "health";
export const SLINKY_CAMPAIGN_ID = "109";
export const SLINKY_IS_TEST = "False";
export const SLINKY_BEARER = "c7209752-af8a-11ec-956e-06f7fbf74470";

export const LP_DEFAULT_PHONE = "18669904167";
export const LP_PHONE_KEY = "e557b69e3ab5ad5e499c2e4f7d9f7ebf";
export const LP_PHONE_CAMP_ID = "62e85262994e0";
export const LP_SUPPLIER_CREDENTIALS = {
  49: {
    //gm
    lp_campaign_id: "62674b7bb02cc",
    lp_campaign_key: "4nGQDk73pq2RcvV8XYPL",
    phoneNumber: "18338344217",
  },
  51: {
    //gm
    lp_campaign_id: "62674b7bb02cc",
    lp_campaign_key: "4nGQDk73pq2RcvV8XYPL",
    phoneNumber: "18338344217",
  },
  54: {
    //so
    lp_campaign_id: "62674c6de7c6c",
    lp_campaign_key: "yJTc2VNrpkGP76ZF4Yjm",
    phoneNumber: "18339963863",
  },
  55: {
    //so
    lp_campaign_id: "62674c6de7c6c",
    lp_campaign_key: "yJTc2VNrpkGP76ZF4Yjm",
    phoneNumber: "18339963863",
  },
  default: {
    lp_campaign_id: "6335acd046620",
    lp_campaign_key: "kMrH6g8bpd2vz9XZwDGm",
    phoneNumber: LP_DEFAULT_PHONE,
  },
};
export const LP_SUCCESS = "success";

export const MA_PLACEMENT_THANKYOU_ID = "e38_jXJ4Pvz9BoeIJjghFn5gUovq9A";
export const MA_PLACEMENT_EXITOFFERS_ID = "xRQ3ryB26LkfGLLcHlOsEoFxixks-A";

export const RINGBA_URL = "";

export const CAKE_URL_IDENTIFIER = "o=22&e=1";
